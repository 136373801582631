import http from "./httpService";

export const addHospital = (data, handleProgressChange) => {
  const options = {
    onUploadProgress: (progressEvent) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  return http.post("/manage/hospitals/add/new", data, options);
};

export const addFacilitySize = (data) => {
  return http.post("/facility/size/add/new", data);
};

export const addFacilityType = (data) => {
  return http.post("/facility/add/new", data);
};

export const getAllHospitals = (city, id) => {
  return http.get("/manage/verified/hospitals/" + city + "/" + id);
};

export const getPreactiveHospitals = () => {
  return http.get("/hospital_success/success/pre-active");
};

export const getDangerHospitals = () => {
  return http.get(
    `/hospital_success/success/hospitals/get-hospitals?status=Danger`
  );
};

export const getFacilityTypes = () => {
  return http.get("/facility/all");
};

export const getFacilitySizes = () => {
  return http.get("/facility/size/all");
};

export const getAllFeedback = (hospitalId) => {
  return http.get("/manage/detailed/verified/hospital/" + hospitalId);
};

export const getHospitalOrderHistory = (refId, hospitalId) => {
  const formData = new FormData();
  formData.append("ref_id", refId);
  formData.append("hospital_id", hospitalId);

  return http.post("/manage/details/pending", formData);
};

const hospitalService = {
  addFacilitySize,
  addFacilityType,
  addHospital,
  getAllFeedback,
  getAllHospitals,
  getFacilitySizes,
  getFacilityTypes,
  getDangerHospitals,
  getPreactiveHospitals,
  getHospitalOrderHistory,
};

export default hospitalService;
