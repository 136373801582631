import React from "react";

import DashboardTopRow from "../../../../components/blocks/dashboardTopRow";
import Header from "../../../../components/blocks/header";

import LeadershipBoard from "../../../../components/blocks/leadershipBoard";

import MarketingTop from "../../../../components/blocks/marketingTop";
import CustomerDashboard from "../customerDashboard";
import HeaderContainer from "../../../../components/blocks/HeaderContainer";
import { Focus } from "./components/focus";

import { useGlobalContext } from "../../../../contexts/globalContext";
import ActivityFeed from "../../../../components/tables/activityFeed";

const Home = () => {
  const { user } = useGlobalContext();

  return user?.position === "marketing" ? (
    <React.Fragment>
      <Header title={`Hello, ${user.username}`} />
      <MarketingTop />
    </React.Fragment>
  ) : user?.position === "customer_success" ? (
    <React.Fragment>
      <Header title={`Hello, ${user.username}`} />
      <CustomerDashboard />
    </React.Fragment>
  ) : user?.position === "sales_rep" ? (
    <React.Fragment>
      <Header title={`Hello, ${user?.username}`} />
      <HeaderContainer />
      <div className="flex flex-col justify-center items-center">
        <Focus />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Header title={`Hello, ${user?.username}`} />
      <div className="px-3">
        <DashboardTopRow />
      </div>
      <div className="flex flex-col-reverse lg:flex-row gap-3">
        <div className="w-full">
          <ActivityFeed path={"home"} />
        </div>
        <div className="bottom-row-breakdown">
          <div className="bottom-row-breakdown-right">
            <div className="bottom-row-breakdown-right-inner">
              <LeadershipBoard />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
