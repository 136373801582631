import { AxiosRequestConfig } from "axios";
import _ from "lodash";

import { OneHospitalUser } from "../ts-utils/interfaces";
import { Position } from "../ts-utils/types";

import config from "../utils/config.js";
import utilities from "../utils/utilities";

import countryService from "./countryService";
import httpService from "./httpService";

const tokenKey: string = config.tokenKey as any;
const loginEndpoint: string = "/authentication/login";
const forgotPassEndpoint: string = "/authentication/forget/password";

export async function login(
  name: string,
  password: string,
  rememberMe: boolean,
  handleProgressChange: (progress: number) => void
): Promise<void> {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: any) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  const apiData = { name, password };
  //
  const response = await httpService.post(loginEndpoint, apiData, options);
  if (response.status !== 200) throw new Error((response.data as any).message);

  const user = _.pick(response.data, [
    "id",
    "email",
    "position",
    "username",
    "country",
    "city",
  ]) as OneHospitalUser;

  storeCurrentUser({ ...user, rememberMe });
}

export async function forgetPassword(
  email: string,

  handleProgressChange: (progress: number) => void
): Promise<void> {
  const options: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: any) =>
      handleProgressChange((progressEvent.loaded * 100) / progressEvent.total),
  };

  const apiData = { email };
  const response = await httpService.post(forgotPassEndpoint, apiData, options);
  if (response.status !== 200) throw new Error((response.data as any).message);

  if (response.status === 200)
    setTimeout(() => {
      window.location.href = "/select-country";
    }, 3000);
}

export function logout(): void {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem("userType");
  countryService.deleteCountryUrl();
}

export function getCurrentUser(): OneHospitalUser | null {
  let item = localStorage.getItem(tokenKey);
  if (!item) return null;

  let user = JSON.parse(item);
  let persistenceSelected: boolean | null = user.value?.rememberMe;
  if (persistenceSelected) return user.value;

  let isExpired: boolean = utilities.checkItemExpired(user.timestamp, "day", 1);
  if (!isExpired) return user.value;

  logout();
  return null;
}

export function getPrivileges() {
  const user: OneHospitalUser | null = getCurrentUser();
  if (!user) return {};

  const checkPermission = (privileges: Position[]): boolean => {
    return privileges.includes((user.position as string).toLowerCase());
  };

  const allPositions: Position[] = utilities.getAllPositions().map((p) => p.id);

  return {
    viewDashboard: checkPermission([
      "cceo",
      "sales_lead",
      "sales_rep",
      "marketing",
      "customer_success",
      "sales_admin",
    ]),
    viewActivityFeed: checkPermission([
      "cceo",
      // "sales_lead",
      "sales_rep",
      "marketing",
      "customer_success",
    ]),
    activeHospitals: checkPermission(["customer_success"]),
    exodus: checkPermission(["sales_rep"]),
    exodusList: checkPermission(["sales_rep"]),
    singleExodus: checkPermission(["sales_rep"]),
    singleExodusOrder: checkPermission(["sales_rep"]),
    exodusActivities: checkPermission(["sales_rep"]),
    exodusComplains: checkPermission(["sales_rep"]),
    exodusContact: checkPermission(["sales_rep"]),
    exodusCall: checkPermission(["sales_rep"]),
    exodusSendDrip: checkPermission(["sales_rep"]),
    exodusSendADrip: checkPermission(["sales_rep"]),
    exodusLogAContact: checkPermission(["sales_rep"]),
    exodusSendNote: checkPermission(["sales_rep"]),
    reviewActiveSchedule: checkPermission(["sales_rep"]),
    allHospitals: checkPermission(["cceo", "sales_lead", "customer_success"]),
    nerveUsage: checkPermission(["cceo", "sales_rep", "sales_admin"]),
    purchasingOrder: checkPermission(["sales_lead"]),
    myHospitals: checkPermission([
      "cceo",
      "sales_lead",
      "sales_rep",
      "sales_admin",
    ]),
    salesAppointments: checkPermission(["sales_rep"]),
    viewHospitals: checkPermission(["sales_lead", "sales_rep", "cceo"]),
    viewHospitalScore: checkPermission(["customer_success"]),
    viewRequests: checkPermission(["sales_rep"]),
    viewAppointments: checkPermission(["marketing", "sales_admin", "cceo"]),
    tools: checkPermission(["marketing"]),
    drip: checkPermission(["sales_lead"]),
    maintenance: checkPermission(["customer_success"]),
    assignActive: checkPermission(["sales_rep"]),
    viewDrip: checkPermission(["marketing"]),
    events: checkPermission(["marketing"]),
    story: checkPermission(["marketing"]),
    dazzle: checkPermission(["marketing"]),
    createHospital: checkPermission(allPositions),
    editProspect: checkPermission([
      "sales_admin",
      "customer_success",
      "fulfilment_officer",
      "cceo",
    ]),
    validateProspect: checkPermission([
      "sales_admin",
      "customer_success",
      "fulfilment_officer",
    ]),
    viewProspected: checkPermission([
      "sales_admin",
      "customer_success",
      "fulfilment_officer",
      "cceo",
    ]),
    viewProspectsPage: checkPermission([
      "sales_admin",
      "customer_success",
      "fulfilment_officer",
      "cceo",
    ]),
    nerveClaims: checkPermission([
      "sales_admin",
      "cceo",
      // "sales_rep",
      // "customer_success",
      // "fulfilment_officer",
    ]),
    viewUnprospected: checkPermission([
      "sales_admin",
      "customer_success",
      "fulfilment_officer",
      "cceo",
    ]),
    viewUnassigned: checkPermission(["sales_admin"]),
    pitch: checkPermission(["sales_rep", "sales_admin"]),
    onboard: checkPermission([
      "customer_success",
      "fulfilment_officer",
      "sales_admin",
    ]),
    setTargets: checkPermission(["cceo", "sales_admin"]),
    viewMessages: checkPermission(allPositions),
    viewOnboard: checkPermission(["sales_rep", "sales_admin"]),
    viewPipelines: checkPermission(allPositions),
    addComplaint: checkPermission(["customer_success"]),
    resolveComplaint: checkPermission(["customer_success"]),
    viewComplaints: checkPermission([
      "sales_rep",
      "fulfilment_officer",
      "customer_success",
      "cceo",
      "sales_lead",
    ]),
    viewSalesStats: checkPermission(["sales_lead"]),
    createAgent: checkPermission(["cceo", "sales_admin"]),
    deleteAgent: checkPermission(["cceo", "sales_admin"]),
    viewLeadArena: checkPermission(["gceo", "cceo", "sales_admin"]),
    viewAgents: checkPermission(["gceo", "cceo", "sales_admin"]),
    viewHibernated: checkPermission(allPositions),
    viewUnsuccessfulPitches: checkPermission(["gceo", "cceo", "sales_lead"]),
    viewByState: checkPermission(["cceo"]),
    viewRevenueMTD: checkPermission(["gceo", "cceo", "sales_lead"]),
    viewCityRevenue: checkPermission(["sales_lead"]),
    viewCountryRevenue: checkPermission(["cceo"]),
    viewAllCountriesRevenue: checkPermission(["gceo"]),
    viewTargetData: checkPermission(allPositions),
    addFacility: checkPermission(["gceo", "cceo"]),
    viewFacilities: checkPermission(["gceo", "cceo", "sales_admin"]),
    addProductType: checkPermission(["gceo", "cceo"]),
    viewProductTypes: checkPermission(["gceo", "cceo", "sales_admin"]),
    viewLeaderBoard: checkPermission([
      "gceo",
      "cceo",
      "sales_lead",
      "sales_admin",
    ]),
    viewAllScoresPage: checkPermission([
      "cceo",
      "sales_lead",
      "sales_rep",
      "customer_success",
      "sales_admin",
    ]),
    viewMyScoreOnly: checkPermission(["sales_rep", "sales_lead"]),
    viewLogistic: checkPermission(["sales_rep", "sales_admin", "cceo", "gceo"]),
  };
}

function storeCurrentUser(user: OneHospitalUser): void {
  const item = {
    value: user,
    timestamp: Date.now(),
  };

  localStorage.setItem(tokenKey, JSON.stringify(item));
}

const authService = {
  getCurrentUser,
  getPrivileges,
  login,
  logout,
};

export default authService;
