import React, { useState } from "react";

import ChangePassword from "../forms/changePassword";
import SendFeedback from "../forms/sendFeedback";
import ArrowBoxes from "./arrowBoxes";

import { useGetDashboard } from "../../hooks/useDashboard";

import { useGlobalContext } from "../../contexts/globalContext";
import SelectServer from "../SelectServer";

export const Footer = () => {
  const { user } = useGlobalContext();
  const { dashboard } = useGetDashboard(user?.id ?? "");

  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [sendFeedback, setSendFeeback] = useState<boolean>(false);
  const [activeCategory, setActiveCategory] = useState(initialCategory);

  const handleFeedback = (): void => {
    setSendFeeback(!sendFeedback);
  };

  const handlePassword = (): void => {
    setChangePassword(!changePassword);
  };

  const currentYear: number = new Date().getFullYear();

  return (
    <React.Fragment>
      {changePassword && <ChangePassword handleClose={handlePassword} />}

      {sendFeedback && <SendFeedback handleClose={handleFeedback} />}

      {user?.position === "sales_rep" && (
        <>
          <div className="flex">
            <ArrowBoxes
              activeCategory={activeCategory}
              onActiveChange={(category: any) => setActiveCategory(category)}
              data={{
                newly_assign: dashboard?.newly_assign,
                assign: dashboard?.assign,
                pitch: dashboard?.pitch,
                close: dashboard?.close,
                exodus: dashboard?.exodus,
                active: dashboard?.active,
                onboard: dashboard?.onboard,
                imported: dashboard?.preexodus,
              }}
            />
          </div>
          <div className="flex justify-around text-xl">
            <p className="font-bold text-purple-900 capitalize"> Sales phase</p>
            <p className="font-bold text-green-700 capitalize">LifeBank phase</p>
          </div>
        </>
      )}

      <div className="dashboard-footer">
        <div className="dashboard-footer-left">
          {currentYear} &copy; OneHospital Dashboard
        </div>

        <div className="dashboard-footer-right">
          <button onClick={handlePassword} className="change-password">
            Change Password
          </button>

          <button onClick={handleFeedback} className="send-feedback">
            Send Feedback
          </button>
        </div>
      </div>
      {process.env.NODE_ENV === "development" && <SelectServer />}
    </React.Fragment>
  );
};

const initialCategory = {
  title: "New",

  tag: "new_hospitals",

  color: "#424200",

  sortKey: "created_at",
};

// console.log(initialCategory);
