import { useState } from "react";
import { useLocation } from "react-router-dom";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";
import { useNavigate } from "react-router";
import { AiOutlineContacts } from "react-icons/ai";
import { MdOutlineHome } from "react-icons/md";
import LogVisit from "./LogVisit";
import { GoDash } from "react-icons/go";
import { useSingleExodus } from "../../../../hooks/useExodus";
import React from "react";
import LoadingButton from "../../../../components/common/loadingButton";
const ContactLog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { state } = location;
  const { singleExodus, isPending } = useSingleExodus(state?.id as string);
  const item = { state };
  const prop = item?.state?.state ?? "";
  const { name, lastOrder } = prop;

  const history = useNavigate();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCall = () => {
    history(`/exodus/call`, {
      state: {
        id: state?.id,
        name: singleExodus ? singleExodus?.hospital_summary[0]?.name : name,
        contactType: "call",
      },
    });
  };

  // const formatNumberWithCommas = (number: number) => {
  //   let numStr = number?.toString();
  //   numStr = numStr?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   return numStr;
  // };

  const formatDate = (item: string) => {
    if (!item) return "N/A";
    const dateObj = new Date(item);

    // Array of month names
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract day, month, and year
    var day = dateObj.getDate();
    var monthIndex = dateObj.getMonth();
    var year = dateObj.getFullYear();

    // Format the date
    var formattedDate = day + " " + monthNames[monthIndex] + " " + year;

    return formattedDate;
  };
  const nerveItem = singleExodus?.ordercounts?.find(
    (item: { channel: string }) => item.channel === "Nerve"
  );

  const nerveCount = nerveItem ? Number(nerveItem.total_sum) : 0;

  return (
    <div className=" w-full">
      <LoadingButton visible={isPending} message="Fetching Hospital..." />
      {!isPending && (
        <>
          <ExodusHeader
            Text={"Log a contact"}
            subText={`| Exodus Arena > Log a contact  -> ${
              singleExodus ? singleExodus?.hospital_summary[0]?.name : "N/A"
            }`}
          />
          <div className="w-11/12 mx-auto mb-4">
            <div className="w-10/12 flex flex-row m-4">
              <p className="text-8xl text-[#49209F] mt-4">
                <GoDash />
              </p>
              <div className="flex-col flex justify-center">
                <p className="font-bold pt-2 pb-2 mt-4 text-2xl">
                  Log a contact to{" "}
                  {singleExodus
                    ? singleExodus?.hospital_summary[0]?.name
                    : "N/A"}
                </p>
              </div>
            </div>

            <div className="bg-[#FFFFFF] w-11/12 mx-auto mt-20 h-[400px] shadow-white shadow-sm rounded relative">
              <div className="top-[-50px] h-[100px] p-4 justify-between left-0 right-0 w-11/12 mx-auto flex bg-[#F5F1FE] shadow-white shadow-sm rounded absolute items-center">
                <p className="text-[16px] font-bold text-[#4D26A0] w-4/12">
                  {singleExodus
                    ? singleExodus?.hospital_summary[0]?.name
                    : "N/A"}
                </p>
                <p className="text-[#7d60c3] mx-auto w-1/12 text-4xl">|</p>
                <p className="text-[16px] w-6/12">
                  {" "}
                  {singleExodus
                    ? singleExodus?.hospital_summary[0]?.address
                    : "N/A"}
                </p>
              </div>
              <div className="flex mx-auto pt-16 mt-8 justify-between items-center w-11/12">
                <div className="text-[#4D26A0] flex font-bold sm:text-[15px] items-center lg:text-[16px]">
                  <p className="mr-1">Nerve Orders Counts : </p>
                  {nerveCount ?? 0}
                </div>

                <div className="text-[#4D26A0] flex font-bold lg:text-[16px] items-center sm:text-[15px]">
                  {" "}
                  <p>Date of Last Order : </p>
                  <span className="sm:text-[15px] lg:text-[16px] text-black font-bold ml-1">
                    {formatDate(
                      singleExodus
                        ? singleExodus?.hospital_summary?.last_order_date
                        : lastOrder
                    )}
                  </span>
                </div>
              </div>
              <hr className="border-t border-black my-4 " />
              <div className="w-9/12 flex justify-between mx-auto gap-4 pt-4">
                <div
                  className="shadow-white shadow-sm rounded w-5/12 flex flex-col items-center h-[200px] justify-around cursor-pointer bg-[#4D26A0] text-[white]"
                  onClick={handleCall}
                >
                  <span className="text-[#4D26A0]">
                    <AiOutlineContacts size={70} className="text-white" />
                  </span>
                  <p className="text-2xl">Log a call</p>
                </div>
                <div
                  className="shadow-white shadow-sm rounded w-5/12 flex flex-col items-center h-[200px] justify-around cursor-pointer bg-[#4D26A0] text-[white]"
                  onClick={handleOpen}
                >
                  <span className="text-[#4D26A0]">
                    <MdOutlineHome size={70} className="text-[#fff]" />
                  </span>
                  <p className="text-2xl text-[#fff]">Log a visit</p>
                </div>
                {isOpen && (
                  <LogVisit
                    setIsOpen={setIsOpen}
                    name={
                      singleExodus
                        ? singleExodus?.hospital_summary[0]?.name
                        : name
                    }
                    id={state?.id}
                    contactType={"visit"}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactLog;
