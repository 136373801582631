import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { GoDash } from "react-icons/go";

import SearchInput from "../../../../components/exodusComponent/Search";
import { useGetAllExodus } from "../../../../hooks/useExodus";
import { useGlobalContext } from "../../../../contexts/globalContext";
import LoadingButton from "../../../../components/common/loadingButton";
import ExodusHeader from "../../../../components/exodusComponent/ExodusHeader";

import applyFilters from "../../../../helpers/urlState";
import { Hospital } from "../../../../ts-utils/types";
import { Link, useLocation } from "react-router-dom";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { RiCloseLargeFill } from "react-icons/ri";
import ProductTable, { ProductTableColumn } from "../../../../components/common/ProductTable";
const ExodusList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const { user } = useGlobalContext();
  const [currentPage, setCurrentPage] = useState(1);
  const status = "exodus";
  const [search, setSearch] = useState("");

  const pageSize: number = 10;
  const filters = {
    user_id: user?.id as string,
    status: status,
    limit: pageSize,
    page: currentPage,
    name: search,
  };
  const { allExodus, paginate, isPending } = useGetAllExodus(
    { ...filters },
    "lite"
  );

  const handleSearch = (e: any) => {
    setSearch(e);
    applyFilters(
      {
        page: 1,
        search: e,
        status,
      },
      navigate,
      "exodus/list"
    );
  };

  useEffect(() => {
    const pageFromUrl = parseInt(searchParams.get("page") || "1", 10);
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [location.search, searchParams, currentPage]);
  const pathname = location.pathname.replace("/", "");
  const goToPage = useCallback(
    (page: number) => {
      applyFilters(
        {
          page,
          search,
          status,
        },
        navigate,
        pathname
      );
      setCurrentPage(page);
    },
    [navigate, pathname, search, status]
  );

  function renderCell(hospital: Hospital, column: ProductTableColumn) {
    switch (column.accessor) {
      case "id":
        return <p className="text-[#49209F] font-bold">{hospital?.id}</p>;
      case "name":
        return (
          <Link
            className="text-[#49209F]  underline font-bold"
            to={`/exodus/list/${hospital?.id}`}
          >
            {hospital?.name}
          </Link>
        );
      case "channel":
        return <span>{hospital?.channel ?? "N/A"}</span>;
      default:
        // For boolean fields, show Yes/No
        return hospital[column.accessor as keyof Hospital] === "Yes" ? (
          <>
            <span className="text-green-600 font-semibold flex items-end md:items-center justify-end md:justify-center text-center">
              <IoCheckmarkDoneSharp />
            </span>
          </>
        ) : (
          <>
            {" "}
            <span className="text-red-500 flex items-end md:items-center justify-end md:justify-center text-center">
              <RiCloseLargeFill />
            </span>
          </>
        );
    }
  }

  const columns: ProductTableColumn[] = [
    { header: "ID", accessor: "id" },
    { header: "Hospital Name", accessor: "name" },
    // { header: "Channel", accessor: "channel" },
    { header: "Blood", accessor: "has_blood_order" },
    { header: "Oxygen", accessor: "has_oxygen_order" },
    { header: "StockBank", accessor: "has_stockbank_order" },
    { header: "Quip", accessor: "has_quip_order" },
  ];
  return (
    <div className="w-full h-full">
      <ExodusHeader
        Text="Number of Hospitals in Exodus"
        subText="Exodus Area > Number of Hospitals in Exodus"
      />
      {isPending ? (
        <div className="flex flex-col justify-between items-center w-full p-5 sm:p-10">
          <LoadingButton
            visible={isPending}
            message="Fetching Data... Please wait"
          />
        </div>
      ) : (
        <div className="">
          <div className="w-10/12 flex flex-row m-4">
            <p className="text-8xl text-[#49209F]">
              <GoDash />
            </p>
            <div className="flex-col flex justify-center">
              <p className="text-2xl  font-bold ">
                Exodus has{" "}
                <span className="text[#49209F]">{paginate?.totalItems}</span>{" "}
                hospitals registered. Details are listed below alphabetically.
              </p>
              <p className="text-2xl  font-bold ">
                Click on the search icon to search for a hospital's name
              </p>
            </div>
          </div>
          <div className=" bg-white  mb-8">
            <SearchInput
              handleSearch={handleSearch}
              placeholder="Enter name of hospital"
            />
          </div>
          <>
            <div className="max-w-screen-2xl mx-auto px-4 md:px-8 mb-5">
              <>
                <ProductTable
                  data={allExodus}
                  columns={columns}
                  renderCell={renderCell}
                  totalPages={paginate?.totalPages}
                  currentPage={currentPage}
                  goToPage={goToPage}
                />
              </>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default ExodusList;
