import { useEffect, useState } from "react";
import { getHospitalOrderHistory } from "../../services/hospitalService";

import Button from "../common/button";
import LoadingButton from "../common/loadingButton";

import utilities from "../../utils/utilities";
import toastService from "../../services/toastService";

import ValidateProspect from "./validateProspect";
import Modal from "../common/modal";

import prospectService from "../../services/prospects.service";
import ProgressLoader from "../common/progressLoader";
import SimilarTable from "../tables/SimilarTable";

import { useGlobalContext } from "../../contexts/globalContext";
import { useGetCadreList } from "../../hooks/agent/useAgent";
import ReactSelect from "../common/ReactSelect";

interface CadreItemProps {
  label: string;
  value: string | null;
  color: string;
}

interface HospitalCadreProps {
  hospitalId: string;
  ref_id: string;
  handleClose: () => void;
}

interface OrderHistory {
  carde: [{ [key: string]: string }];
  demo_order: any[];
  similar: any[];
}

const HospitalCadre = (props: HospitalCadreProps) => {
  const { cadreList, isPending } = useGetCadreList();

  const [orderHistory, setOrderHistory] = useState(initialOrderHistory);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequest] = useState(false);
  const [requestChange, setRequestChange] = useState("");

  const [showValidate, setShowValidate] = useState(false);
  const [showNewCadre, setShowNewCadre] = useState(false);

  const [cadre, setCadre] = useState({
    blood: "",
    oxygen: "",
    ref: "",
  });

  const handleValidate = () => {
    setShowValidate(!showValidate);
  };

  const getOrderHistory = async () => {
    if (!props.hospitalId) return;
    if (!props.ref_id) return;

    try {
      setIsLoading(true);

      const response: any = await getHospitalOrderHistory(
        props.ref_id,
        props.hospitalId
      );
      setOrderHistory(response.data);

      setIsLoading(false);
    } catch (ex) {
      const errorMessage: string = utilities.getErrorMessageFromException(ex);
      toastService.error(errorMessage);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrderHistory();

    // eslint-disable-next-line
  }, [props.ref_id]);

  const [progress, setProgress] = useState(0);

  const { user } = useGlobalContext();

  const validate = async () => {
    const apiData = {
      user_id: user?.id,
      hospital_id: props.hospitalId,
      reason: requestChange,
      blood: cadre.blood,
      oxygen: cadre.oxygen,
    };

    const res: any = await prospectService.validateHospital(
      apiData,
      handleProgress
    );
    if (!res.data.ok) throw new Error(res.data.description);

    setShowNewCadre(!showNewCadre);
    props.handleClose();
  };

  // const handleProgress = (progress) => {
  //   setProgress(progress);
  // };

  const handleProgress = (progress: number) => {
    setProgress(progress);
  };

  const handleValidation = () => {
    toastService.promise(validate(), {
      loading: "Validating...",
      success: () => "Validated Successfully",
      error: (e: Error) => e.message,
      // error: (e) => e.message,
    });
  };

  return (
    <div className="hospital_orders">
      <div className="hospital_orders_body">
        <h4>Cadre (Subscription)</h4>

        <LoadingButton
          visible={isLoading}
          message="Fetching hospital information, please wait"
        />

        <div className="p-3 bg-light rounded border">
          <div className="container-fluid">
            <div className="row">
              <CadreItem
                label="Blood"
                color="#b80304"
                value={orderHistory?.carde?.[0]?.Blood}
              />

              <CadreItem
                label="Oxygen"
                color="#0679f8"
                value={orderHistory?.carde?.[0]?.Oxygen}
              />

              <CadreItem
                label="Stockbank"
                color="#006400"
                value={orderHistory?.carde?.[0]?.Stockbank}
              />
            </div>

            <div className="py-6 flex justify-center ">
              <>
                {isLoading ? (
                  <LoadingButton
                    visible={isLoading}
                    message="Fetching hospital information, please wait"
                  />
                ) : (
                  <div className="w-full">
                    <SimilarTable similarData={orderHistory?.similar} />
                  </div>
                )}
              </>
            </div>
          </div>
        </div>

        {isRequest && (
          <div
            style={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              justifySelf: "center",
              marginTop: "2rem",
            }}
          >
            <div>
              <div>
                <ReactSelect
                  isLoading={isPending}
                  placeholder="Select Blood Cadre"
                  options={
                    Array.isArray(cadreList)
                      ? cadreList?.map(
                          (cadre: { id: string; name: string }) => ({
                            value: cadre.name,
                            label: cadre.name,
                          })
                        )
                      : []
                  }
                  onChange={(value) =>
                    setCadre({
                      ...cadre,
                      blood: value,
                    })
                  }
                />
                <ReactSelect
                  isLoading={isPending}
                  placeholder="Select Oxygen Cadre"
                  options={
                    Array.isArray(cadreList)
                      ? cadreList?.map(
                          (cadre: { id: string; name: string }) => ({
                            value: cadre.name,
                            label: cadre.name,
                          })
                        )
                      : []
                  }
                  onChange={(value) =>
                    setCadre({
                      ...cadre,
                      oxygen: value,
                    })
                  }
                />
              </div>
              {/* <Select
                placeholder="Select Blood Cadre"
                options={cadreTypes}
                value={bloodCadre}
                onChange={(e: any) => setBloodCadre(e.currentTarget.value)}
              />
              <Select
                placeholder="Select Oxygen Cadre"
                options={cadreTypes}
                value={oxygenCadre}
                onChange={(e: any) => setOxygenCadre(e.currentTarget.value)}
              /> */}
            </div>
            <textarea
              id="summary"
              name="requestCadre"
              onChange={(e) => setRequestChange(e.target.value)}
              placeholder="Reason for changing cadre"
              rows={5}
              cols={50}
              className="form-field-input-text"
            ></textarea>

            {/* <div
              className="status primary"
              onClick={() => setShowNewCadre(!showNewCadre)}
            >
              Submit
            </div> */}

            <Button
              label="Submit and Validate"
              onClick={() => setShowNewCadre(!showNewCadre)}
            />
          </div>
        )}
        <div
          style={{
            float: "right",
            display: "flex",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          {/* <div className="d-flex justify-content-end">
            <Button
              label="Request Cadre Change"
              onClick={() => setIsRequest(!isRequest)}
            />
          </div> */}
          <div className="d-flex justify-content-end">
            <Button label="Validate" onClick={handleValidate} />
          </div>

          <div className="d-flex justify-content-end">
            <Button label="Close" onClick={props.handleClose} />
          </div>
        </div>
      </div>

      {showValidate && (
        <ValidateProspect
          selectedProspect={props.hospitalId}
          handleClose={handleValidate}
          handleExit={props.handleClose}
          blood={orderHistory?.carde?.[0]?.Blood}
          oxygen={orderHistory?.carde?.[0]?.Oxygen}
        />
      )}

      {showNewCadre && (
        <Modal handleClose={() => setShowNewCadre(!showNewCadre)}>
          <ProgressLoader progress={progress} />

          <div className="w-100 text-center">
            <i className="fa fa-exclamation-circle confirm-validate-icon"></i>
          </div>

          <h3 className="modal-title confirm-validate-title">Are You Sure?</h3>

          <p className="confirm-validate-text">
            You won't be able to reverse this
          </p>

          <div className="modal-center-buttons">
            <Button label="Yes" onClick={handleValidation} />

            <Button
              label="No"
              className="app-btn close-btn"
              onClick={() => setShowNewCadre(!showNewCadre)}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

const CadreItem = (props: CadreItemProps) => {
  const opacity: number = props.value ? 1 : 0.6;

  return (
    <div className="hospital_orders_cadre col" style={{ opacity }}>
      <div className="hospital_orders_cadre_icon">
        <i className="fa fa-star" style={{ color: props.color }}></i>
      </div>
      <div className="hospital_orders_cadre_label">{props.label}</div>
      <div
        className="hospital_orders_cadre_value"
        style={{ backgroundColor: props.color }}
      >
        {props.value ?? "No Cadre Set"}
      </div>
    </div>
  );
};

const initialOrderHistory: OrderHistory = {
  carde: [{}],
  demo_order: [],
  similar: [],
};

export default HospitalCadre;
