import productService from "../services/product.service";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { AxiosResponse } from "axios";
import toastService from "../services/toastService"; 
import utilities from "../utils/utilities";
 

export const useGetProductList = () => {
  const { isError, isSuccess, data, isPending } = useQuery({
    queryKey: ["productList"],
    queryFn: async () => {
      const response: any =
        (await productService.getProductList()) as AxiosResponse<any[]>;
      return response.data;
    }, 
  });

  const products: any = data?.data || [];
  const productList: any[] = utilities.mapItemsFromObjects(products);
  productList.sort((a, b): number => {
    const nameA: string = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB: string = b.name.toUpperCase(); // ignore upper and lowercase

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
  });

  return { productList, isError, isSuccess, isPending };
};

export const useAddProduct = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const { isError, isSuccess, isPending, mutate } = useMutation({
    mutationFn: async (data: any) => {
      const response = (await productService.addProduct(
        data,
        handleProgressChange
      )) as AxiosResponse<any[]>;
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Product added successfully!");
      queryClient.invalidateQueries({
        queryKey: ["productList"],
      });
    },
  });

  return { isError, isSuccess, isPending, mutate };
};
