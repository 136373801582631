import SaleType from "./saleType";
import { useLocation } from "react-router-dom";

import { useGetPitchInformation } from "../../../../hooks/sales/useAppointment";

const PitchForm = ({ navigation }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const info = searchParams.get("id");

  const { pitchInformation, isPending } = useGetPitchInformation(info);

  return (
    <div>
      <SaleType selectedPitch={pitchInformation[0]} loading={isPending} />
    </div>
  );
};

export default PitchForm;
