import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import marketingService from "../../services/marketing.service";
import { AxiosError, AxiosResponse } from "axios";
//
import toastService from "../../services/toastService";

interface ResponseData {
  description: string;
  data: any;
  meta: any;
  baseUrl: string;
}

export const useGetEvents = () => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["events"],
    queryFn: async () => {
      const response =
        (await marketingService.getAllEvents()) as AxiosResponse<ResponseData>;
      return response;
    },
  });

  const events: any = (data as AxiosResponse<ResponseData>)?.data?.data || [];
  const baseUrl = (data as AxiosResponse<ResponseData>)?.data?.baseUrl;
  if (isError) {
    // toastService.error((error as AxiosError)?.message);
    toastService.error("No event found");
  }

  return { isError, isSuccess, events, baseUrl, isPending, error };
};

// get single event
export const useGetSingleEvent = (id: string | null) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["event", id],
    enabled: !!id,
    queryFn: async () => {
      const response = (await marketingService.getEvent(
        id as string
      )) as AxiosResponse<ResponseData>;
      return response;
    },
  });

  const event: any = (data as AxiosResponse<ResponseData>)?.data?.data || {};
  if (isError) {
    toastService.error((error as AxiosError)?.message);
  }

  return { isError, isSuccess, event, isPending, error };
};

// create events
export const useCreateEvent = (
  handleProgressChange: (progress: number) => void
) => {
  //   const { user } = useGlobalContext();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: any) => {
      const response = await marketingService.createEvent(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data) => {
      toastService.success("Event created successfully!");
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to create event");
    },
  });

  return { mutate, isPending, error };
};

// archive events
export const useArchiveEvent = () => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateArchive,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (id: string) => {
      const response = await marketingService.archiveEvent(id as string);
      return response;
    },
    onSuccess: (data) => {
      toastService.success("Event archived successfully!");
      queryClient.refetchQueries({
        queryKey: ["events"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to archive event");
    },
  });

  return { mutateArchive, isPending, error };
};

// update events
export const useUpdateEvent = (
  id: string | number,
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateUpdate,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await marketingService.updateEvent(
        id as string,
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data) => {
      toastService.success("Event updated successfully!");
      queryClient.refetchQueries({
        queryKey: ["events"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to update event");
    },
  });

  return { mutateUpdate, isPending, error };
};
