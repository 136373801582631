import { useNavigate } from "react-router-dom";
import { PipelineItem } from "../../ts-utils/interfaces";
import { cn } from "../../@/lib/utils";

const ArrowBoxes = ({
  activeCategory,
  onActiveChange,
  data,
}: any): JSX.Element => {
  const pipelineItems: PipelineItem[] = [
    {
      title: "Newly Assigned",
      tag: "newly_assign",
      color: "#424200",
      icon: "hospital-o",
      sortKey: "created_at",
      data: data?.newly_assign,
    },
    // {
    //   title: "Assigned",
    //   tag: "assign",
    //   color: "#424200",
    //   icon: "hospital-o",
    //   sortKey: "created_at",
    //   data: data?.assign,
    // },
    {
      title: "Pitch",
      tag: "pitches",
      color: "#441AB7",
      icon: "newspaper-o",
      sortKey: "pitch_date",
      data: data?.pitch,
    },
    {
      title: "Close",
      tag: "close",
      color: "#b71C1C",
      icon: "plus-circle",
      sortKey: "prospect_date",
      data: data?.close,
    },
    {
      title: "Onboard",
      tag: "onboard",
      color: "#424200",
      icon: "book",
      sortKey: "onboard_date",
      data: data?.onboard,
    },

    // {
    //   title: "Pre Exodus",
    //   tag: "Imported",
    //   color: "#09A5C5",
    //   icon: "ticket",
    //   sortKey: "stalled_date",
    //   data: data?.imported,
    // },
    // {
    //   title: "Exodus",
    //   tag: "exodus",
    //   color: "#663300",
    //   icon: "ticket",
    //   sortKey: "stalled_date",
    //   data: data?.exodus,
    // },
    // {
    //   title: "Active",
    //   tag: "active",
    //   color: "#114B29",
    //   icon: "check-circle",
    //   sortKey: "active_date",
    //   data: data?.active,
    // },
  ];

  const salesPipelineItems: PipelineItem[] = [
    {
      title: "Pre Exodus",
      tag: "Imported",
      color: "#09A5C5",
      icon: "ticket",
      sortKey: "stalled_date",
      data: data?.imported,
    },
    {
      title: "Exodus",
      tag: "exodus",
      color: "#663300",
      icon: "ticket",
      sortKey: "stalled_date",
      data: data?.exodus,
    },
    {
      title: "Active",
      tag: "active",
      color: "#114B29",
      icon: "check-circle",
      sortKey: "active_date",
      data: data?.active,
    },
  ];

  const router = useNavigate();
  const navigate = (path: string) => {
    router(`/pipeline?content=${path}`);
    // history(`/pipeline?content=${path}`);
  };
  return (
    <>
      <ul id="breadcrumb" className="border-b-4 border-b-purple-900">
        {pipelineItems.map((pipelineItem: PipelineItem) => {
          const isActive: boolean =
            pipelineItem.tag.toLowerCase() === activeCategory.tag.toLowerCase();
          return (
            <li
              key={pipelineItem.title}
              className={cn(isActive ? "active" : "inactive")}
              style={{ width: `calc(100%/4)` }}
              onClick={() => {
                window.sessionStorage.setItem(
                  "pipeline",
                  JSON.stringify(pipelineItem)
                );
                navigate(pipelineItem.tag);
                onActiveChange(pipelineItem);
              }}
            >
              <span
                className="arrowLeft"
                style={{
                  borderColor: pipelineItem.color,
                  borderLeftColor: "transparent",
                }}
              ></span>
              <p
                className="flex gap-2 text-nowrap"
                style={{ backgroundColor: pipelineItem.color }}
              >
                <i className={"fa fa-" + pipelineItem.icon}></i>{" "}
                <span>{pipelineItem.title} </span>
                <span>{pipelineItem.data ?? 0}</span>
              </p>
              <span
                className="arrowRight"
                style={{ borderLeftColor: pipelineItem.color }}
              ></span>
            </li>
          );
        })}
      </ul>
      <ul id="breadcrumb" className="border-b-4 border-b-green-700">
        {salesPipelineItems.map((pipelineItem: PipelineItem) => {
          const isActive: boolean =
            pipelineItem.tag.toLowerCase() === activeCategory.tag.toLowerCase();
          return (
            <li
              key={pipelineItem.title}
              className={isActive ? "active" : "inactive"}
              style={{ width: `calc(100%/3)` }}
              onClick={() => {
                window.sessionStorage.setItem(
                  "pipeline",
                  JSON.stringify(pipelineItem)
                );
                navigate(pipelineItem.tag);
                onActiveChange(pipelineItem);
              }}
            >
              <span
                className="arrowLeft"
                style={{
                  borderColor: pipelineItem.color,
                  borderLeftColor: "transparent",
                }}
              ></span>

              <p
                className="flex gap-2 text-nowrap"
                style={{ backgroundColor: pipelineItem.color }}
              >
                <i className={"fa fa-" + pipelineItem.icon}></i>{" "}
                <span>{pipelineItem.title} </span>
                <span>{pipelineItem.data ?? 0}</span>
              </p>
              {pipelineItem.tag !== "active" && (
                <span
                  className="arrowRight"
                  style={{ borderLeftColor: pipelineItem.color }}
                ></span>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ArrowBoxes;
