import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import appointmentService, {
  Appointment,
} from "../../services/appointment.service";
import { AxiosError, AxiosResponse } from "axios";

import toastService from "../../services/toastService";
import { useGlobalContext } from "../../contexts/globalContext";

interface ResponseData {
  description: string;
  data: any;
  meta: any;
}

export const useGetAppointment = () => {
  const { user } = useGlobalContext();
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["appointment", user?.city, user?.id],
    enabled: !!user?.city || !!user?.id,
    queryFn: async () => {
      if (user?.position === "cceo") {
        const response = (await appointmentService.getAllAcquisition(
          user.city
        )) as AxiosResponse<ResponseData>;
        return response;
      } else {
        const response: Appointment[] =
          (await appointmentService.getAllAppointments(
            user?.id as string
          )) as Appointment[];
        return response;
      }
    },
  });

  const appointment: any =
    (data as AxiosResponse<ResponseData>)?.data?.data || [];

  if (isError) {
    toastService.error((error as AxiosError)?.message);
  }

  return { isError, isSuccess, appointment, isPending, error };
};

export const useGetAcquisition = (city: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["acquisition", city],
    enabled: !!city,
    queryFn: async () => {
      const response = await appointmentService.getAllAcquisition(city);
      return response;
    },
  });

  const acquisition: any =
    (data as AxiosResponse<ResponseData>)?.data?.data || [];

  if (isError) {
    toastService.error((error as AxiosError)?.message);
  }

  return { isError, isSuccess, acquisition, isPending, error };
};

export const useGetPitchInformation = (hospitalId: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["pitch", hospitalId],
    enabled: !!hospitalId,
    queryFn: async () => {
      const response = await appointmentService.getPitchInformation(hospitalId);
      return response;
    },
  });

  const pitchInformation: any =
    (data as AxiosResponse<ResponseData>)?.data?.data || [];

  if (isError) {
    toastService.error((error as AxiosError)?.message);
  }

  return { isError, isSuccess, pitchInformation, isPending, error };
};

export const useSendToClose = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: any) => {
      const response = await appointmentService.sendToClose(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Pipeline updated successfully!");
      queryClient.invalidateQueries({
        queryKey: ["pipeline", "dashboard"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to update pipeline");
    },
  });

  return { mutate, isPending, error };
};

export const useCloseAppointment = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (data: any) => {
      const response = await appointmentService.closeAppointment(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      console.log(data, "close pipeline");
      toastService.success("Pipeline updated successfully!");
      queryClient.invalidateQueries({
        queryKey: ["pipeline", "dashboard"],
      });
    },
    onError: (error: { response: { data: { message: string } } }) => {
      toastService.error(
        error.response.data.message || "Failed to update pipeline"
      );
    },
  });

  return { mutate, isPending, error };
};

export const useGetSinglePendingRequest = (id: string) => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["pending"],
    queryFn: async () => {
      const response = await appointmentService.getSinglePendingRequests(id);
      return response;
    },
  });

  const pitchInfo: any =
    (data as AxiosResponse<ResponseData>)?.data?.data?.[0] || [];

  if (isError) {
    toastService.error((error as AxiosError)?.message);
  }

  return { isError, isSuccess, pitchInfo, isPending, error };
};

export const useScheduleAppointment = (
  handleProgressChange: (progress: number) => void
) => {
  const queryClient = useQueryClient();
  const {
    mutate: mutateSchedule,
    isPending,
    error,
  } = useMutation({
    mutationFn: async (data: any) => {
      const response = await appointmentService.scheduleAppointment(
        data,
        handleProgressChange
      );
      return response;
    },
    onSuccess: (data: any) => {
      toastService.success("Appointment scheduled successfully!");
      queryClient.invalidateQueries({
        queryKey: ["appointment", "pipeline", "dashboard"],
      });
    },
    onError: (error: { message: string }) => {
      toastService.error(error.message || "Failed to schedule appointment");
    },
  });

  return { mutateSchedule, isPending, error };
};

export const useGetMaintenance = () => {
  const { isError, isSuccess, data, isPending, error } = useQuery({
    queryKey: ["maintenance"],
    queryFn: async () => {
      const response = await appointmentService.getMaintenanceSchedule();
      return response;
    },
  });

  const maintenance: any =
    (data as AxiosResponse<ResponseData>)?.data?.data || [];

  if (isError) {
    toastService.error((error as AxiosError)?.message);
  }

  return { isError, isSuccess, maintenance, isPending, error };
};
