import { PersistConfig } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import cartReducer from "./slices/Cart";
import quotationReducer from "./slices/Quotations";

export type RootState = ReturnType<typeof rootReducer>;

const rootPersistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
  keyPrefix: "OHA-",
  whitelist: ["cart", "quotation"],
};

const rootReducer = combineReducers({
  cart: cartReducer,
  quotation: quotationReducer,
});

export { rootPersistConfig, rootReducer };
