import httpService from "../services/httpService";
import config from "../utils/config";

export const dashboardData = (id: string | number) => {
  return httpService.get(`/admin/new/dashboard/data/${id}`);
};

export const getData = (userId: string) => {
  return httpService.get("/admin/dashboard/data/" + userId);
};

export const getFeed = (userId: string) => {
  return httpService.get("/admin/news/feed/" + userId);
};

export const getAgentRevenue = (userId: string, date_range: string) => {
  return httpService.get(
    `/admin/agent/revenue/${userId}?date_range=${date_range}`
  );
};

export const getTargetRevenue = (userId: string) => {
  return httpService.get("/admin/target/indie/" + userId);
};

export const getTotalHospitals = (userId?: string) => {
  return httpService.get(`/admin/hospitals/all?userid=${userId}`);
};

export const setTarget = (data: any) => {
  return httpService.post("/admin/target/monthly", data);
};
export const getCities = (country: string) => {
  const url = config.countriesApiUrl + "/countries/states";

  return httpService.post(url, { country });
};

export const getHospitalsCount = (userId?: string) => {
  return httpService.get(`/admin/hospitals/count?userid=${userId}`);
};

const dashboardService = {
  dashboardData,
  getData,
  getFeed,
  getAgentRevenue,
  getTargetRevenue,
  getTotalHospitals,
  setTarget,
  getCities,
  getHospitalsCount,
};

export default dashboardService;
